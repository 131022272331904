<template>
    <div v-if="salon">
        <header class="header">
            <v-container fluid>
                <v-row class="ml-10 mr-10">
                    <v-col cols="12" sm="6" class="d-flex align-center justify-center justify-sm-start">
                        <v-img alt="Quiz Addict - Reper" :src="'/assets/logos/' + $i18n.locale + '/general.png'" max-width="160" />
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex align-center justify-center justify-sm-end">
                        <v-btn color="#C1C1C1" elevation="0" class="mr-sm-10 pt-4 pb-4 pl-6 pr-6 white--text" to="/">{{ $t("show-quizes") }}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </header>
        <v-main>
            <v-img :src="'/assets/backgrounds/background-groupe/background-groupe-' + salon.category + '.jpg'" min-height="100vh">
                <v-container>
                    <div v-if="step == 'starting' || step == ''" class="d-flex justify-center align-center" style="height: calc(100vh - 68px)">
                        <div class="timer starter">
                            <span v-if="count > 0">{{ count }}</span>
                            <span v-else>{{ $t("ready") }}</span>
                        </div>
                    </div>

                    <v-card v-else class="ma-12 mx-6 pt-6" :class="step == 'question' ? 'px-6' : ''" style="position: relative">
                        <template v-if="step == 'question'">
                            <v-row class="mb-lg-10">
                                <v-col cols="12">
                                    <p class="question-number" :style="'color:' + salon.color + ' !important;'">
                                        {{ $t("question") }}
                                        {{ questions.findIndex((current) => current.id == question.id) + 1 }}
                                        /
                                        {{ questions.length }}
                                    </p>
                                    <v-divider :style="'border-color:' + salon.color + ' !important;'"></v-divider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" lg="6" order-lg="2" class="d-flex justify-center">
                                    <v-img
                                        v-if="question.mediatype === 'img'"
                                        :src="question.media"
                                        contain
                                        width="auto"
                                        max-height="300px"
                                        max-width="400px"
                                        style="border: 6px solid #eee"
                                    />
                                    <video
                                        v-if="question.mediatype === 'video'"
                                        class="ma-0 ma-md-8 mt-0 mb-0"
                                        width="auto"
                                        max-height="300px"
                                        max-width="400px"
                                        controls
                                    >
                                        <source :src="question.mediavideo" type="video/mp4" />
                                    </video>
                                    <youtube-media
                                        v-if="question.mediatype === 'embeded'"
                                        :video-id="question.vidid"
                                        player-width="auto"
                                        class="ma-0 ma-md-8 mt-0 mb-0"
                                    ></youtube-media>
                                </v-col>
                                <v-col cols="12" lg="6" order-lg="1" class="d-flex align-left">
                                    <div class="ma-0 mt-0">
                                        <h2 v-if="question.context" class="question-context" v-text="question.context"></h2>
                                        <p class="question-title" v-text="question.question"></p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-center">
                                    <div class="timer question">
                                        <v-progress-circular
                                            v-if="count > 0"
                                            :rotate="-90"
                                            :size="200"
                                            :width="30"
                                            :value="(100 / salon.time) * count"
                                            :color="
                                                count < salon.time / 4
                                                    ? 'red'
                                                    : count < salon.time / 2
                                                    ? 'orange'
                                                    : count < (salon.time / 4) * 3
                                                    ? 'yellow darken-1'
                                                    : 'green'
                                            "
                                        >
                                            {{ count.toFixed(1) }}
                                        </v-progress-circular>
                                    </div>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-if="step == 'results'" class="d-flex justify-center">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" :xs="12" :md="6">
                                        <v-card-text class="ma-0 ml-xl-10 mt-0 pt-0">
                                            <p class="answer-title" v-text="question.question"></p>
                                            <ul style="list-style-type: none; margin-top: 30px">
                                                <li
                                                    v-for="an in question.answers"
                                                    :key="an.id"
                                                    class="mb-3 answers"
                                                    style="line-height: 30px"
                                                    :style="an.correct === '1' ? 'color: #4CAF50' : ''"
                                                >
                                                    <v-icon color="green" v-if="an.correct === '1'" style="position: absolute; margin-left: -30px">
                                                        mdi-check
                                                    </v-icon>
                                                    {{ an.answer }}
                                                </li>
                                            </ul>

                                            <p v-if="question.explication_short" v-text="question.explication_short" class="mt-8"></p>
                                            <p v-if="question.explication && detail === false">
                                                <a href="#" @click="detail = true" style="font-size: 18px">{{ $t("more") }}</a>
                                            </p>

                                            <p class="mx-0 mb-10" v-html="question.explication" v-if="detail"></p>
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12" :xs="12" :md="6">
                                        <v-img
                                            class="ma-0 mr-md-10 mt-0 ml-md-4 d-flex align-center text-center"
                                            :src="question.media"
                                            contain
                                            max-height="300px"
                                        ></v-img>

                                        <v-row v-if="responses.length != 0" class="align-center mr-md-8 mt-4">
                                            <v-col cols="12" xl="4">
                                                <p class="results ml-md-4">
                                                    {{ $t("results") }}
                                                </p>
                                            </v-col>
                                            <v-col cols="12" sm="6" xl="4" class="text-center display-1">
                                                <div class="correct pl-9 pt-5 pb-5 pr-5 d-flex justify-center align-center">
                                                    <p class="answers-number mr-4">
                                                        {{ checkResponses(true) }}
                                                    </p>
                                                    <v-img src="/assets/icons/circle-check.svg" class="answers-icon"></v-img>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="6" xl="4" class="text-center display-1">
                                                <div class="wrong pl-9 pt-5 pb-5 pr-5 d-flex justify-center align-center">
                                                    <p class="answers-number mr-4">
                                                        {{ checkResponses(false) }}
                                                    </p>
                                                    <v-img src="/assets/icons/circle-plus.svg" class="answers-icon"></v-img>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else class="mr-md-8">
                                            <v-col cols="12" class="text-center mt-4">
                                                <p class="results">
                                                    {{ $t("no-responses") }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" :sm="12" class="d-flex justify-center justify-md-end align-md-end"></v-col>
                                </v-row>
                            </v-container>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-end">
                                    <v-btn
                                        v-if="salon.type == 'challenge'"
                                        @click="showLeaderboard"
                                        color="primary"
                                        class="mt-2 mr-2 ma-md-10 mt-md-4 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn"
                                    >
                                        {{ $t("leaderboard-btn") }}
                                        <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
                                    </v-btn>
                                    <v-btn v-else @click="next" color="primary" class="mt-2 mr-2 ma-md-10 mt-md-4 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn">
                                        {{ $t("next-question") }}
                                        <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </v-card>
                </v-container>
            </v-img>
        </v-main>
    </div>
</template>

<script>
import { db } from "../firebase";

export default {
    name: "SalonMng",
    props: ["code"],
    data: () => ({
        index: 0,
        question: {},
        step: "",
        salon: {},
        count: 4,
        currentQuestion: null,
        questions: [],
        detail: false,
        correct: false,
        time: 10,
        players: [],
        responses: [],
    }),
    methods: {
        async letsgo() {
            if (this.salon.type == "challenge") {

                if (this.step != "results") {
                    this.step = "starting";
                    await this.countdown();
                } else {
                    this.question = this.questions[this.index].question;
                    await this.showResults();
                }
            } else {
                this.step = "starting";
                await this.countdown();
            }
        },
        async countdown() {
            if (this.count <= 0.001) {
                if (this.step === "starting") {
                    await this.makeQuestion();
                } else {
                    await this.showResults();
                }
                return false;
            }
            if (this.step == "question") {
                this.count = this.count - 0.1;
                setTimeout(async () => await this.countdown(), 100);
            } else if (this.step == "starting") {
                this.count = this.count - 1;
                setTimeout(async () => await this.countdown(), 1000);
            }
        },
        checkResponses(correct) {
            let number = 0;
            this.responses.forEach((response) => {
                if (response.answer == correct) number++;
            });

            return number;
        },
        async makeQuestion() {
            db.collection("salons").doc(this.salon.id).set(
                {
                    startTime: new Date().getTime(),
                },
                { merge: true }
            );

            this.count = this.time;

            if (this.index > this.questions.length - 1) {
                await db.collection("salons").doc(this.code).update({
                    step: "finish",
                });
                this.$router.push("/salon-finish/" + this.code);
                return;
            }

            this.question = this.questions[this.index].question;

            await db.collection("salons").doc(this.code).update({
                workingquestion: this.question,
                step: "question",
            });

            await this.$bind("currentQuestion", db.collection("salons/" + this.code + "/questions").doc(this.salon.workingquestion.id));

            this.step = "question";
            await this.countdown();
        },
        async showLeaderboard() {
            this.index++;
            await db.collection("salons").doc(this.salon.id).set(
                {
                    index: this.index,
                },
                { merge: true }
            );
            this.step = "results";
            await db.collection("salons").doc(this.code).update({
                step: "results",
            });
            this.$router.push("/salon-finish/" + this.code);
        },
        async showResults() {
            db.collection("salons").doc(this.code).update({
                step: "results",
            });

            const questionDocument = await db
                .collection("/salons/" + this.code + "/questions")
                .doc(this.question.id)
                .get();

            if (!questionDocument.exists || questionDocument.data().responses == undefined) return;

            this.responses = questionDocument.data().responses;
            this.step = "results";
        },
        async next() {
            this.index = this.index + 1;
            await db.collection("salons").doc(this.salon.id).set(
                {
                    index: this.index,
                },
                { merge: true }
            );
            this.detail = false;
            await this.makeQuestion();
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.$i18n.locale === "de") {
                    document.title = "Bischfit | by REPER";
                } else {
                    document.title = "Quizaddict | by REPER";
                }
            },
        },
        currentQuestion: async function () {
            const currentQuestion = await db
                .collection("salons/" + this.code + "/questions/")
                .doc(this.salon.workingquestion.id)
                .get();

            if (!currentQuestion.exists) return;

            if (currentQuestion.data().responses != undefined && currentQuestion.data().responses.length >= this.players.length) {
                this.step = "results";
                await this.showResults();
            }
        },
    },
    async mounted() {
        await this.$bind(
            "questions",
            db
                .collection("/salons/" + this.code + "/questions")
                .where("position", ">=", 0)
                .orderBy("position", "asc")
        );

        await this.$bind("players", db.collection("/salons/" + this.code + "/players"));
        await this.$bind("results", db.collection("/salons/" + this.code + "/results"));
        await this.$bind("salon", db.collection("salons").doc(this.code));
        this.time = this.salon.time;
        this.step = this.salon.step;
        if (this.salon.index) this.index = this.salon.index;
        if (this.index > this.questions.length - 1) {
            await db.collection("salons").doc(this.code).update({
                step: "finish",
            });
            this.$router.push("/salon-finish/" + this.code);
            return;
        }
        await this.letsgo();
    },
};
</script>

<style lang="scss">
:root {
    --primary-color: #b20028 !important;
    --secondary-color: #b20028 !important;
}

.v-progress-circular__overlay {
    transition: all 0.1s ease-in-out;
}

.starter {
    width: 100%;
}

.timer.starter {
    width: auto;
    color: #ffffff;
    font-family: Roboto;
    font-size: 200px;
    font-weight: 900;
    text-shadow: 0 10px 0 0 rgba(0, 0, 0, 0.14);
}
.timer.question {
    height: 100px;
    background: #fff;
    font-weight: bold;
    border-radius: 50px;
    line-height: 100px;
    font-size: 30px;
    text-align: center;

    svg {
        background-color: white;
        border-radius: 50%;

        box-sizing: border-box;
        border: 5px solid #ffffff;
        box-shadow: -7px 2px 0 0 rgba(0, 0, 0, 0.21);
    }

    .v-progress-circular__info {
        z-index: 10;
        font-family: "Fredoka One";
        font-size: 60px;
        letter-spacing: 0;
        line-height: 72px;
    }
}

h3 {
    margin-top: 50px;
    font-weight: normal;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: auto;
    color: white;
}

.next-btn {
    font-family: "Fredoka One", cursive;
    font-size: 20px !important;
}

.question-context {
    color: #333333;
    font-family: Roboto !important;
    font-size: 25px !important;
    letter-spacing: 0 !important;
    line-height: 30px !important;
}

.question-title {
    color: #333333;
    font-family: Roboto;
    font-size: 28px !important;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    line-height: 35px !important;
}

.answer-title {
    color: #333333;
    font-family: Roboto;
    font-size: 25px !important;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    line-height: 35px !important;
}

.question-number {
    font-family: "Fredoka One" !important;
    font-size: 20px !important;
    letter-spacing: 0 !important;
    line-height: 35px !important;
    margin-bottom: 0px !important;
}

.v-divider {
    border-width: 2px !important;
}

.answers {
    color: #333333;
    font-family: Roboto;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 30px;
}

.results {
    margin-bottom: 0 !important;
    color: #333333;
    font-family: "Fredoka One" !important;
    font-size: 25px !important;
    letter-spacing: 0 !important;
    line-height: 37px !important;
}

.correct {
    box-sizing: border-box;
    border: 6px solid #31b500;
    border-radius: 8px;
    background-color: #e1f7d9;
}

.answers-number {
    margin-bottom: 0 !important;
    color: #333333;
    font-family: "Fredoka One" !important;
    font-size: 30px !important;
    letter-spacing: 0 !important;
    line-height: 37px !important;
}

.wrong {
    box-sizing: border-box;
    border: 6px solid #e61e27;
    border-radius: 8px;
    background-color: #f9edee;
}

.answers-icon {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
}

.v-responsive__content {
    width: auto !important;
}
</style>
