<template>
    <div>
        <header class="header">
            <v-container fluid>
                <v-row class="ml-10 mr-10">
                    <v-col cols="12" sm="6" class="d-flex align-center justify-center justify-sm-start">
                        <v-img alt="Quiz Addict - Reper" :src="'/assets/logos/' + $i18n.locale + '/general.png'" max-width="160" />
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex align-center justify-center justify-sm-end">
                        <v-btn color="#C1C1C1" elevation="0" class="mr-sm-10 pt-4 pb-4 pl-6 pr-6 white--text" to="/">{{ $t("show-quizes") }}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </header>
        <span class="bg"></span>
        <v-main v-if="salon" class="backgroundImg" style="min-height: calc(100vh - 237px)">
            <v-img :src="'/assets/backgrounds/background-groupe/background-groupe-' + salon.category + '.jpg'" min-height="100vh">
                <v-container class="text-center">
                    <h1 class="mb-8 mt-12 white--text">
                        {{ $t("quiz-group-title") }}
                    </h1>
                </v-container>
                <v-container>
                    <v-card class="ma-4">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="10">
                                    <!-- <v-row>
                                        <v-col>
                                            <h2 class="ml-10 mt-10">
                                                {{ salon.type == "challenge" ? $t("mode-c") : $t("mode-e") }}
                                            </h2>
                                        </v-col>
                                    </v-row> -->
                                    <v-row>
                                        <v-col cols="10" lg="7" class="ml-10">
                                            <h3 class="mt-0">{{ $t("part-here") }} :</h3>
                                            <div class="mt-3 quiz-info-container">
                                                <h3 class="link mt-0">{{ $t("link") }}/play/start</h3>
                                            </div>
                                        </v-col>
                                        <v-col cols="10" lg="4" class="ml-10 ml-lg-0 mb-4 mb-lg-0">
                                            <h3 class="mt-0">{{ $t("pin") }} :</h3>
                                            <div class="mt-3 quiz-info-container">
                                                <h3 class="code mt-0">
                                                    {{ code.substring(0, 3) }}
                                                    {{ code.substring(3) }}
                                                </h3>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="2" class="d-flex justify-center justify-md-end">
                                    <v-img
                                        class="my-4 mb-md-0 mt-md-10 mr-md-10"
                                        contain
                                        position="top"
                                        :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=http://www.quiz-addict.ch/play/' + code"
                                        max-width="200"
                                    />
                                </v-col>
                            </v-row>
                            <v-card class="ml-10 mr-10 mt-4 d-flex pa-12 justify-center align-center flex-wrap players-container" elevation="0">
                                <div class="blink_text">
                                    {{ $t("waiting-players") }}
                                </div>
                                <v-card elevation="0" class="player" v-for="(player, i) in players" :key="player.name + '-' + i">
                                    <h5>
                                        {{ player.name }}
                                    </h5>
                                </v-card>
                            </v-card>
                            <v-row class="mt-4 mt-md-0">
                                <v-col class="d-flex justify-md-end">
                                    <v-btn
                                        :to="'/salon-mng/' + code"
                                        color="primary"
                                        class="mt-8 mb-8 ml-10 mr-md-10 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn"
                                    >
                                        {{ $t("start") }}
                                        <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-container>
            </v-img>
        </v-main>
    </div>
</template>

<script>
import { db } from "../firebase";

export default {
    name: "SalonWaiting",
    props: ["code"],
    data: () => ({
        salon: null,
        players: [],
    }),
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.$i18n.locale === "de") {
                    document.title = "Bischfit | by REPER";
                } else {
                    document.title = "Quizaddict | by REPER";
                }
            },
        },
    },
    created() {
        this.$bind("salon", db.collection("salons").doc(this.code));
        this.$bind("players", db.collection("/salons/" + this.code + "/players"));
    },
};
</script>

<style lang="scss">
:root {
    --primary-color: #b20028 !important;
    --secondary-color: #b20028 !important;
}
.player {
    min-height: 50px !important;
    min-width: 110px !important;
    border-radius: 6px !important;
    background-color: #333333 !important;

    h5 {
        text-align: center;
        color: #ffffff;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 37px;
    }
}
// .backgroundImg {
//   /* background: #fbb700;*/
//   background-position: center;
//   background-size: cover;
//   width: 100vw;
// }

// .backgroundImg > .v-content__wrap > .container {
//   height: auto;
// }

// @media screen and (min-width: 609px) and (max-width: 960px) {
//   .backgroundImg > .v-content__wrap > .container {
//     height: auto;
//   }
// }

// @media screen and (max-width: 608px) {
//   .backgroundImg > .v-content__wrap > .container {
//     height: auto;
//   }
// }

// @media screen and (max-width: 960px) and (max-height: 1000px) {
//   .backgroundImg > .v-content__wrap > .container {
//     height: auto;
//   }
// }

// @media screen and (min-width: 961px) and (max-height: 715px) {
//   .backgroundImg > .v-content__wrap > .container {
//     height: auto;
//   }
// }

h3 {
    margin-top: 50px;
    font-weight: normal;
}

.players-container {
    min-height: calc(100vh - 700px);
    box-sizing: border-box !important;
    border: 6px solid #e8e8e8 !important;
    border-radius: 8px !important;
    background-color: #f0f0f0 !important;

    @media screen and (min-width: 1264px) {
        min-height: calc(100vh - 800px);
    }
}

.blink_text {
    position: absolute;
    color: #b6b6b6;
    font-family: "Fredoka One";
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0;

    animation-name: blinker;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @media screen and (min-width: 600px) {
        font-size: 30px;
        line-height: 33px !important;
    }

    @media screen and (min-width: 1264px) {
        line-height: 37px !important;
    }
}

.text-h3,
.text-h4,
.text-h5 {
    font-weight: bold !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: auto;
    color: white;
}

.quiz-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 6px solid #f0b835;
    border-radius: 8px;
    background-color: #f1c55d;

    .link {
        padding: 10px 30px;
        font-size: 25px !important;
        line-height: 20px !important;

        @media screen and (min-width: 600px) {
            padding: 30px;
            font-size: 30px !important;
            line-height: 23px !important;
        }

        @media screen and (min-width: 1264px) {
            font-size: 35px !important;
            line-height: 28px !important;
        }
    }

    .code {
        padding: 15px 30px;
        font-size: 40px !important;
        line-height: 40px !important;

        @media screen and (min-width: 600px) {
            padding: 20px 34px;
            line-height: 43px !important;
        }

        @media screen and (min-width: 1264px) {
            line-height: 48px !important;
        }
    }
}

.next-btn {
    box-shadow: none !important;
    font-family: "Fredoka One", cursive;
    font-size: 20px !important;
}

.v-btn.v-size--default {
    font-size: unset;
}

@keyframes blinker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}
</style>
