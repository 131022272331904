import axios from "axios";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

let url = window.location.href;
localStorage.quizkey = url.split("/")[3];

let burl = "https://admin.quiz-addict.ch/api/de/quiz";
if (
    window.location.host == "quiz-addict.ch" ||
    window.location.host == "www.quiz-addict.ch" ||
    window.location.host == "preprod.quiz-addict.ch" ||
    window.location.host == "localhost:8080" ||
    window.location.host == "localhost:8081"
) {
    burl = "https://admin.quiz-addict.ch/api/fr/quiz";
}
const baseURL = burl;
//const baseURL = '//quiz-adict.test/api/quiz'

const apiCall = ({ url, data, method }) =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                if (method == "get") {
                    resolve(axios[method](baseURL + url));
                } else if (method == "post") {
                    resolve(axios[method](baseURL + url, data));
                } else if (method == "put") {
                    resolve(axios[method](baseURL + url, data));
                } else if (method == "patch") {
                    resolve(axios[method](baseURL + url, data));
                }
            } catch (err) {
                reject(new Error(err));
            }
        }, 1000);
    });

export default apiCall;
