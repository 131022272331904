import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}
var lng = "de";
if (
    window.location.host == "quiz-addict.ch" ||
    window.location.host == "www.quiz-addict.ch" ||
    window.location.host == "preprod.quiz-addict.ch" ||
    window.location.host == "localhost:8080" ||
    window.location.host == "localhost:8081"
) {
    lng = "fr";
}

export default new VueI18n({
    locale: lng || "en",
    fallbackLocale: lng || "en",
    messages: loadLocaleMessages(),
});
