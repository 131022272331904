<template>
  <div v-if="!loading">
    <v-app-bar
      app
      color="white"
      dark
      style="height: 100px; padding: 20px 0 20px 0"
    >
      <v-container fluid>
        <v-row>
          <v-col cols="5" sm="6">
            <router-link to="/">
              <v-img
                alt="Logo Reper - Promotion de la santé"
                src="/logo.svg"
                width="190px"
                contain
              />
            </router-link>
          </v-col>
          <v-col cols="7" sm="6" class="d-flex align-center justify-end pr-6">
            <v-toolbar-title class="primary--text text-right font-weight-medium"
              >Quiz addict</v-toolbar-title
            >
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <span class="bg"></span>
    <v-content style="padding-top: 100px; min-height: calc(100vh - 68px)">
      <v-container>
        <h1>Mode salon</h1>
        <div class="d-flex justify-begin align-center mt-4">
          <h3 class="headline">Statistiques</h3>
        </div>
        <v-row elevation="1" class="mt-12">
          <v-col cols="1"></v-col>
          <v-col><strong>Questions</strong></v-col>
          <v-col cols="1"><strong>Correct</strong></v-col>
          <v-col cols="1"><strong>Faux</strong></v-col>
        </v-row>
        <v-row
          v-for="(question, i) in quiz.questions"
          :key="question.id"
          class="mt-4"
          elevation="1"
        >
          <v-col cols="1">{{ i + 1 }}</v-col>
          <v-col>{{ question.question }}</v-col>
          <v-col cols="1"
            ><v-chip color="green" class="white--text">{{
              responses[i].correct
            }}</v-chip></v-col
          >
          <v-col cols="1"
            ><v-chip color="red" class="white--text">{{
              responses[i].false
            }}</v-chip></v-col
          >
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import apiCall from '@/utils/api.js'
import { db } from '../firebase'

export default {
  name: 'Statistiques',
  props: ['salon'],
  data: () => ({
    name: '',
    code: '',
    step: 1,
    categories: [],
    cat: null,
    myquiz: {},
    loading: true,
    responses: [],
  }),
  created() {
    localStorage.setItem('salon', this.salon)
    db.collection('salons')
      .doc(this.salon)
      .get()
      .then((snapshot) => {
        apiCall({ url: '/' + snapshot.data().quizkey, method: 'get' }).then(
          (resp) => {
            this.$vuetify.theme.themes.light.primary = resp.data.main_color
            this.$vuetify.theme.themes.light.secondary = resp.data.main_color
            this.quiz = resp.data

            localStorage.quiz = JSON.stringify(resp.data)
            this.background =
              "background:url('" +
              this.quiz.background_front +
              "'); background-size:cover;"
            this.logo = this.quiz.logo
            this.partners = this.quiz.partners

            this.$bind(
              'responses',
              db.collection('salons/' + this.salon + '/questions')
            )
            this.loading = false

            localStorage.result = 0
          }
        )
      })
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$i18n.locale === 'de') {
          document.title = 'Bischfit | by REPER'
        } else {
          document.title = 'Quizaddict | by REPER'
        }
      },
    },
  },
}
</script>

<style>
.backgroundImg {
  /* background: #fbb700;*/
  background-position: center;
  background-size: cover;
  width: 100vw;
}

.backgroundImg > .v-content__wrap > .container {
  height: auto;
}

@media screen and (min-width: 609px) and (max-width: 960px) {
  .backgroundImg > .v-content__wrap > .container {
    height: auto;
  }
}

@media screen and (max-width: 608px) {
  .backgroundImg > .v-content__wrap > .container {
    height: auto;
  }
}

@media screen and (max-width: 960px) and (max-height: 1000px) {
  .backgroundImg > .v-content__wrap > .container {
    height: auto;
  }
}

@media screen and (min-width: 961px) and (max-height: 715px) {
  .backgroundImg > .v-content__wrap > .container {
    height: auto;
  }
}

h3 {
  margin-top: 50px;
  font-weight: normal;
}
</style>
