<template>
    <div>
        <header class="header">
            <v-container fluid>
                <v-row class="ml-md-10 mr-md-10">
                    <v-col sm="4" class="d-flex align-center justify-center justify-md-start">
                        <v-img alt="Quiz Addict - Reper" :src="'/assets/logos/' + $i18n.locale + '/general.png'" max-width="160" />
                    </v-col>
                    <v-col sm="8" class="d-flex align-center justify-end header-actions">
                        <v-btn color="primary" elevation="0" class="d-md-flex mr-2 mr-sm-10 pt-4 pb-4 pl-6 pr-6" to="/salon/home">
                            {{ $t("quiz-group") }}
                        </v-btn>
                        <div class="d-flex">
                            <v-btn
                                :color="$i18n.locale === 'fr' ? 'primary' : 'grey'"
                                href="https://www.quiz-addict.ch/"
                                elevation="0"
                                class="mr-2 pt-4 pb-4 pl-4 pr-4"
                            >
                                FR
                            </v-btn>
                            <v-btn
                                :color="$i18n.locale === 'de' ? 'primary' : 'grey'"
                                href="https://www.bisch-fit.ch"
                                elevation="0"
                                class="pt-4 pb-4 pl-4 pr-4"
                            >
                                DE
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </header>

        <v-main class="background">
            <v-container class="background-inside text-center">
                <h1 class="mb-8 mt-12 primary--text">
                    {{ $t("quiz-concept") }}
                </h1>
                <p class="intro text-xs-caption text-md-h5 px-xs-4 px-md-12">
                    {{ $t("quiz-description") }}
                </p>

                <v-row class="list">
                    <v-col>
                        <v-expansion-panels accordion class="mt-12" elevation="0">
                            <v-expansion-panel v-for="category in categories" :key="category.id" :disabled="category.quizes.length < 1">
                                <v-expansion-panel-header class="text-h6 text-md-h4" :class="'panel panel-' + category.key">
                                    <v-img
                                        :src="'/assets/icons/quiz-addict_icon_' + category.key + '.svg'"
                                        :max-width="$vuetify.breakpoint.smAndDown ? 30 : 40"
                                        class="mr-4 mr-sm-6 mr-md-12"
                                    ></v-img>
                                    {{ category.category }}
                                    <template v-slot:actions>
                                        <svg
                                            width="29px"
                                            height="16px"
                                            viewBox="0 0 29 16"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                        >
                                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Theme" transform="translate(-1071.000000, -63.000000)" :stroke="colors[category.key]" stroke-width="5">
                                                    <g id="Fleche---Alcool" transform="translate(1072.687500, 65.000000)">
                                                        <polyline id="Path-2" points="-2.70386867e-15 0 12.8125 10 25.625 0"></polyline>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list>
                                        <template v-for="(quiz, i) in category.quizes">
                                            <v-list-item :key="quiz.key + i + '_item'" class="pa-2">
                                                <v-img
                                                    :src="'/assets/icons/quiz-addict_icon_' + category.key + '.svg'"
                                                    max-width="30"
                                                    class="mr-12 quiz-image"
                                                ></v-img>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-left text-sm-caption text-md-h6" v-text="quiz.title"></v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn
                                                        :class="'btn-' + category.key"
                                                        class="pt-4 pb-4 pl-6 pr-6 text-capitalize btn-quiz"
                                                        :to="'/' + quiz.key"
                                                    >
                                                        {{ $t("play-quiz") }}
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-divider :key="quiz.key + i + '_divider'" v-if="i < category.quizes.length - 1" class="mt-7 mb-7"></v-divider>
                                        </template>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <footer class="footer">
            <Swiper :options="swiperOption" class="swiper-container">
                <SwiperSlide v-for="partner in partners" :key="partner" class="d-flex justify-center mb-4">
                    <v-img :src="'/assets/partners/logo-' + partner + '.jpg'" contain width="210px" height="130px"></v-img>
                </SwiperSlide>
            </Swiper>
            <v-row class="d-flex align-center mt-2">
                <v-col cols="12">
                    <p v-if="$i18n.locale == 'fr'" class="mx-10 caption">
                        Des questions ? Une suggestion de thème pour un quiz ? Écrivez-nous à
                        <a href="mailto:projets@reper-fr.ch">projets@reper-fr.ch</a>
                    </p>
                    <p v-else class="mx-10 caption">
                        Haben Sie Fragen? Haben Sie einen Themenvorschlag oder eine Quizidee? Schreiben Sie uns an
                        <a href="mailto:projekte@reper-fr.ch">projekte@reper-fr.ch</a>
                    </p>
                </v-col>
                <v-col cols="12" md="4" lg="5">
                    <v-img src="/assets/logos/reper.svg" max-width="235px" class="ml-10 mt-3 mb-3"></v-img>
                </v-col>
                <v-col cols="12" md="8" lg="7">
                    <p class="caption copyright mx-10 ma-0">
                        {{ "©Association REPER " + new Date().getFullYear() + " ." }}
                        Tous droits réservés - Rue Hans-Fries 11 - 1700 Fribourg
                    </p>
                </v-col>
            </v-row>
        </footer>
    </div>
</template>

<script>
import apiCall from "@/utils/api.js";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
    name: "Home",
    components: {
        Swiper,
        SwiperSlide,
    },
    data: () => ({
        categories: [],
        colors: {
            sport: "#6d4371",
            hasard: "#82b623",
            ecrans: "#004c76",
            multi: "#f2bb3f",
            cannabis: "#52821d",
            alcool: "#820f01",
            general: "#f2bb3f",
            tabac:"#ea5f5c",
            alimentation: "#00a9a4",
        },
        partners: ["etat", "noetic", "ocn", "skppsc", "cool-clean", "action-innocence", "pildj", "cipret"],
        swiperOption: {
            slidesPerView: 4,
            spaceBetween: 30,
            grabCursor: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                delay: 2500,
                disableOnInteraction: true,
            },
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
            },
        },
    }),
    created() {
        localStorage.setItem("salon", "");
        apiCall({ url: "", method: "get" }).then((resp) => {
            this.categories = resp.data;
        });
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.$i18n.locale === "de") {
                    document.title = "Bischfit | by REPER";
                } else {
                    document.title = "Quizaddict | by REPER";
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !important;
}
.v-expansion-panels {
    .v-expansion-panel {
        border-radius: 7px;
        padding: 10px;
        margin: 10px 0;
    }
    margin-bottom: 150px !important;
}

.alimentation {
    color: #00a9a4 !important;
}

.sport {
    color: #6d4371 !important;
}
.hasard {
    color: #82b623 !important;
}
.ecrans {
    color: #004c76 !important;
}
.multi {
    color: #f2bb3f !important;
}
.cannabis {
    color: #52821d !important;
}
.alcool {
    color: #820f01 !important;
}
.general {
    color: #f2bb3f !important;
}
.tabac {
    color: #f2bb3f !important;
}
.footer-ecrans {
    color: #004c76 !important;
}
.footer-multi {
    color: #f2bb3f !important;
}
.footer-cannabis {
    color: #52821d !important;
}
.footer-alcool {
    color: #820f01 !important;
}
.footer-general {
    color: #f2bb3f !important;
}
.footer-tabac {
    color: #ea5f5c !important;
}

.btn-alimentation,
.footer-alimentation {
    background: #00a9a4 !important;
    color: white !important;
}

.btn-sport,
.footer-sport {
    background: #6d4371 !important;
    color: white !important;
}

.btn-hasard,
.footer-hasard {
    background: #82b623 !important;
    color: #ffffff !important;
}
.btn-ecrans,
.footer-ecrans {
    background: #004c76 !important;
    color: #ffffff !important;
}
.btn-multi,
.footer-multi {
    background: #f2bb3f !important;
    color: #ffffff !important;
}

.btn-cannabis,
.footer-cannabis {
    background: #52821d !important;
    color: #ffffff !important;
}
.btn-alcool,
.footer-alcool {
    background: #820f01 !important;
    color: #ffffff !important;
}
.btn-general,
.footer-general {
    background: #f2bb3f !important;
    color: #ffffff !important;
}
.btn-tabac,
.footer-tabac {
    background: #ea5f5c !important;
    color: #ffffff !important;
}
.text-h3,
.text-h4,
.text-h5 {
    font-weight: bold !important;
}
.text-h7 {
    font-size: 30px;
}
body {
}
.header,
.footer {
    z-index: 1;
    position: relative;
    background: #fff;

    .header-actions {
        justify-content: space-between;

        @media screen and (min-width: 961px) {
            justify-content: flex-end;
        }
    }

    .caption,
    .copyright {
        font-size: 15px !important;

        @media screen and (min-width: 961px) {
            text-align: right;
        }
    }
}

.quiz-image {
    display: none;
    @media screen and (min-width: 961px) {
        display: flex;
    }
}

.background {
    > div,
    .background-inside,
    .v-main__wrap,
    > * {
        position: relative;
        z-index: 1 !important;
        min-height: 800px;
        max-width: 1140px;
    }

    .v-main__wrap {
        min-height: 50vh;
    }

    &:after {
        content: "";
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vh;
        z-index: 0;
        min-height: 500px;
        background-image: url("/assets/backgrounds/background-quizaddict-large.jpg");
        background-size: cover;
    }
}

.v-list-item {
    flex-direction: column;
    min-height: auto !important;

    @media screen and (min-width: 600px) {
        flex-direction: row;
    }

    .v-list-item__title {
        font-size: 22px !important;
        line-height: 25px !important;
        white-space: unset !important;
    }
}

.btn-quiz {
    box-shadow: none !important;
    font-size: 18px !important;
    line-height: 21px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: auto;
}

.swiper-container {
    border-bottom: 2px solid lightgray;
}
</style>
