<template>
    <div>
        <header class="header">
            <v-container fluid>
                <v-row class="ml-10 mr-10">
                    <v-col cols="12" sm="6" class="d-flex align-center justify-center justify-sm-start">
                        <v-img alt="Quiz Addict - Reper" :src="'/assets/logos/' + $i18n.locale + '/general.png'" max-width="160" />
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex align-center justify-center justify-sm-end">
                        <v-btn color="#C1C1C1" elevation="0" class="mr-sm-10 pt-4 pb-4 pl-6 pr-6 white--text" to="/">{{ $t("show-quizes") }}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </header>
        <v-main style="min-height: 700px">
            <v-img src="/assets/backgrounds/background-groupe/background-groupe-alcool.jpg" min-height="100vh">
                <v-container class="text-center">
                    <h1 class="mb-8 mt-12 white--text">
                        {{ $t("quiz-group-title") }}
                    </h1>
                </v-container>
                <v-container>
                    <v-card class="ma-4 mt-0">
                        <v-container>
                            <v-row class="justify-center">
                                <v-col class="ml-8 mr-8">
                                    <v-card-text class="ma-0 mt-4 mb-4" style="font-size: unset">
                                        <h3 class="mt-0">
                                            {{ $t("descrsalon1") }}
                                        </h3>
                                        <p class="mt-6 mb-6">
                                            {{ $t("descrsalon11") }}
                                        </p>
                                        <ol>
                                            <li>
                                                {{ $t("descrsalon21") }}
                                            </li>
                                            <li>
                                                {{ $t("descrsalon22") }}
                                            </li>
                                            <li>
                                                {{ $t("descrsalon23") }}
                                            </li>
                                        </ol>
                                        <p class="mt-6 mb-6">
                                            {{ $t("descrsalon3") }}
                                        </p>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col>
                                <v-btn
                                    class="ma-sm-8 mt-8 ml-2 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn primary--text"
                                    :href="'/assets/documents/Quiz-ADDICT_Explication-A4_' + $i18n.locale + '.pdf'"
                                    download="quiz-addict-explications"
                                >
                                    {{ $t("pdf") }}
                                    <v-icon class="ml-2">mdi-download</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col class="d-flex justify-end">
                                <v-btn to="/salon/select" color="primary" class="ma-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn">
                                    {{ $t("next") }}

                                    <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-container>
            </v-img>
        </v-main>
    </div>
</template>
<script>
export default {
    name: "SalonHome",
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.$i18n.locale === "de") {
                    document.title = "Bischfit | by REPER";
                } else {
                    document.title = "Quizaddict | by REPER";
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.text-h3 {
    font-weight: bold !important;
}
</style>
