<template>
    <div>
        <header class="header">
            <v-container fluid>
                <v-row class="ml-10 mr-10">
                    <v-col cols="12" sm="6">
                        <router-link to="/" style="height: 100%; width: 100%" class="d-flex align-center justify-center justify-sm-start">
                            <v-img alt="Quiz Addict - Reper" :src="'/assets/logos/' + $i18n.locale + '/' + quiz.class + '.png'" max-width="140"></v-img>
                        </router-link>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <a
                            href="https://www.reper-fr.ch"
                            target="_blank"
                            style="height: 100%; width: 100%"
                            class="d-flex align-center justify-center justify-sm-end"
                        >
                            <v-img alt="Logo Reper - Promotion de la santé" src="/assets/logos/reper.svg" position="right" contain max-width="190"></v-img>
                        </a>
                    </v-col>
                </v-row>
            </v-container>
        </header>
        <v-main class="backgroundImg" style="padding-top: 30px; min-height: calc(100vh - 177px)" v-bind:style="background">
            <v-container v-if="questions" class="d-flex align-center justify-center">
                <v-card width="1200" class="mt-10 mx-2 mb-4">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <h4
                                    class="question-number mx-8 mt-8"
                                    :class="quiz.class"
                                    :style="'border-color:' + quiz.main_color + ';color:' + quiz.main_color"
                                >
                                    {{ $t("question") }}
                                    <span v-text="parseInt(nb) + 1"></span>
                                    /
                                    <span v-text="quiz.questions.length"></span>
                                </h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" :sm="12" :md="6" order-md="2">
                                <v-img
                                    v-if="question.mediatype === 'img'"
                                    class="ma-0 mx-8 mt-0 mb-2"
                                    :src="question.media"
                                    contain
                                    max-width="100%"
                                    max-height="300px"
                                    style="border: 6px solid #eee"
                                />
                                <video v-if="question.mediatype === 'video'" class="ma-0 mx-8 mt-0 mb-0" width="90%" max-height="400px" controls>
                                    <source :src="question.mediavideo" type="video/mp4" />
                                </video>
                                <youtube-media
                                    v-if="question.mediatype === 'embeded'"
                                    :video-id="question.vidid"
                                    player-width="100%"
                                    class="ma-0 mx-md-8 mt-0 mb-0"
                                ></youtube-media>
                            </v-col>
                            <v-col cols="12" :sm="12" :md="6" order-md="1" class="pb-0 d-flex flex-column align-center">
                                <h3 v-if="question.context" class="question-text ml-8">
                                    {{ question.context }}
                                </h3>
                                <p class="question-text font-weight-bold ml-8 mt-8" v-text="question.question"></p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" :sm="12" :md="8" class="d-flex align-end pt-0">
                                <v-card-text class="ml-8 pa-0">
                                    <v-alert dismissible type="warning" v-show="alert">{{ $t("Warning-1-question") }}</v-alert>
                                    <v-radio-group v-if="question.type == 'singleChoice'" v-model="answer" class="mt-0">
                                        <v-radio v-for="answer in question.answers" :key="answer.id" :label="answer.answer" :value="answer.id"></v-radio>
                                    </v-radio-group>

                                    <div v-if="question.type == 'multipleChoice'">
                                        <v-checkbox
                                            v-for="an in question.answers"
                                            :key="an.id"
                                            :label="an.answer"
                                            :value="an.id"
                                            v-model="answers"
                                            class="ma-0 pa-0"
                                            style="min-height: 36px"
                                        ></v-checkbox>
                                    </div>
                                </v-card-text>
                            </v-col>
                            <v-col cols="12" :sm="12" :md="4" class="d-flex justify-center align-center justify-md-end align-md-end">
                                <v-btn class="ma-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn" :class="'btn-' + quiz.class" @click="rate" dark>
                                    {{ $t("check") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-container>
        </v-main>
        <footer>
            <v-container fluid class="d-md-flex justify-end">
                <p v-if="showpartners && quiz.partners.length > 0" class="my-4 mr-1" style="text-align: center; margin: 0">
                    {{ $t("partner") }}
                </p>
                <p v-if="showpartners" class="my-4 mx-0 mr-md-10" style="text-align: center; margin: 0">
                    <template v-for="(partner, i) in quiz.partners">
                        <a :key="partner.uri" :href="partner.uri" style="text-decoration: none" target="_blank">
                            {{ partner.title }}
                        </a>
                        <span v-if="i != quiz.partners.length - 1 && quiz.partners.length > 1" :key="i + '-span'">·</span>
                    </template>
                </p>
            </v-container>
        </footer>
    </div>
</template>

<script>
import { db } from "../firebase";

export default {
    name: "Question",
    props: ["nb", "quizkey"],
    data() {
        return {
            showpartners: false,
            alert: false,
            quiz: JSON.parse(localStorage.quiz),
            questions: [],
            question: {},
            answers: [],
            answer: 0,
        };
    },
    methods: {
        rate() {
            if (!this.answer && this.answers.length === 0) {
                this.alert = true;
                return;
            }
            var correct = false;

            if (this.answer) {
                for (let answer of this.question.answers) {
                    if (answer.id === this.answer) {
                        if (answer.correct === "1") {
                            correct = true;
                        }
                    }
                }
            } else {
                var nbCorrect = 0;
                var temp = 0;
                for (let answer of this.question.answers) {
                    if (answer.correct === "1") {
                        nbCorrect++;
                    }
                }

                for (let answer of this.question.answers) {
                    if (answer.correct === "1") {
                        for (let an of this.answers) {
                            if (an === answer.id) {
                                temp++;
                            }
                        }
                    } else {
                        for (let an of this.answers) {
                            if (an === answer.id) {
                                temp--;
                            }
                        }
                    }
                }

                if (temp === nbCorrect) {
                    correct = true;
                } else {
                    correct = false;
                }
            }

            if (correct) {
                localStorage.result = parseInt(localStorage.result) + 1;
            }
            const responses = localStorage.getItem("questionsRep");
            if (responses != null) {
                const tempResp = responses.split(",").concat([this.nb, correct]);
                localStorage.setItem("questionsRep", tempResp.join(","));
            } else {
                localStorage.setItem("questionsRep", [this.nb, correct].join(","));
            }

            let salon = localStorage.getItem("salon");
            if (salon) {
                db.collection("salons/" + salon + "/questions/")
                    .doc(this.question.id)
                    .get()
                    .then((snapshot) => {
                        let response = snapshot.data();
                        let data = {};
                        if (correct) {
                            data = {
                                correct: response.correct + 1,
                                false: response.false,
                            };
                        } else {
                            data = {
                                correct: response.correct,
                                false: response.false + 1,
                            };
                        }
                        db.collection("salons/" + salon + "/questions/")
                            .doc(this.question.id)
                            .set(data)
                            .then(() => {
                                this.$router.push("/" + this.quizkey + "/check/" + this.nb + "/" + correct);
                            });
                    });
            } else {
                this.$router.push("/" + this.quizkey + "/check/" + this.nb + "/" + correct);
            }
        },
    },
    created() {
        const responses = localStorage.getItem("questionsRep");
        if (responses != null) {
            if (responses.split(",").includes(this.nb)) {
                this.$router.push(
                    "/" + this.quizkey + "/check/" + this.nb + "/" + responses.split(",")[responses.split(",").findIndex((resp) => resp == this.nb) + 1]
                );
            }
        }
        this.showpartners = this.quiz.partners.length;
        this.background = "background:url('/assets/backgrounds/background-" + this.quiz.class + ".jpg'); background-size:cover;background-position:center;";
        this.questions = this.quiz.questions;
        this.question = this.quiz.questions[this.nb];
    },
};
</script>

<style>
.alimentation {
    color: #00a9a4 !important;
}

.sport {
    color: #6d4371 !important;
}
.hasard {
    color: #82b623 !important;
}
.ecrans {
    color: #004c76 !important;
}
.multi {
    color: #f2bb3f !important;
}
.cannabis {
    color: #52821d !important;
}
.alcool {
    color: #820f01 !important;
}
.general {
    color: #f2bb3f !important;
}

.btn-alimentation,
.footer-alimentation {
    background: #00a9a4 !important;
    color: white !important;
}

.btn-sport,
.footer-sport {
    background: #6d4371 !important;
    color: white !important;
}

.btn-hasard,
.footer-hasard {
    background: #82b623 !important;
    color: #ffffff !important;
}
.btn-ecrans,
.footer-ecrans {
    background: #004c76 !important;
    color: #ffffff !important;
}
.btn-multi,
.footer-multi {
    background: #f2bb3f !important;
    color: #ffffff !important;
}
.btn-cannabis,
.footer-cannabis {
    background: #52821d !important;
    color: #ffffff !important;
}
.btn-alcool,
.footer-alcool {
    background: #820f01 !important;
    color: #ffffff !important;
}
.btn-general,
.footer-general {
    background: #f2bb3f !important;
    color: #ffffff !important;
}
h2 {
    padding-top: 20px;
    font-weight: normal;
    font-size: 180%;
    line-height: 130%;
    padding-bottom: 20px;
}
h1 {
    line-height: 130%;
    padding-top: 20px;
}
h4 {
    font-weight: normal;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
}
.footer {
    min-width: 71px;
}

@media screen and (max-width: 1000px) {
    .logo {
        width: 130px;
    }
}
@media screen and (max-width: 800px) {
    .logoreper {
        width: 100px;
    }
}

.v-btn:not(.v-btn--round).v-size--default {
    height: auto;
    color: white;
}

.next-btn {
    font-family: "Fredoka One", cursive;
    font-size: 20px !important;
}

.question-number {
    font-family: "Fredoka One" !important;
    font-size: 20px !important;
    letter-spacing: 0 !important;
    line-height: 35px !important;
    margin-bottom: 0px !important;
}

.question-text {
    color: #333333;
    font-family: Roboto !important;
    font-size: 25px !important;
    letter-spacing: 0 !important;
    line-height: 35px !important;
}
</style>
