import Vue from 'vue'
import VueRouter from 'vue-router'
import QuizHome from '../views/QuizHome.vue'
import QuizQuestion from '../views/QuizQuestion.vue'
import QuizCheck from '../views/QuizCheck.vue'
import QuizResult from '../views/QuizResult.vue'
import QuizList from '../views/QuizList.vue'
import SalonHome from '../views/SalonHome.vue'
import SalonSelect from '../views/SalonSelect.vue'
import SalonWaiting from '../views/SalonWaiting.vue'
import SalonFinish from '../views/SalonFinish.vue'
import SalonMng from '../views/SalonMng.vue'
import Play from '../views/Play.vue'
import PlayCode from '../views/PlayCode.vue'
import Statistiques from '../views/Statistiques.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'QuizList',
    component: QuizList,
    props: true,
  },
  {
    path: '/:quizkey',
    name: 'QuizHome',
    component: QuizHome,
    props: true,
  },
  {
    path: '/:quizkey/question/:nb',
    name: 'QuizQuestion',
    component: QuizQuestion,
    props: true,
  },
  {
    path: '/:quizkey/check/:nb/:correct',
    name: 'QuizCheck',
    component: QuizCheck,
    props: true,
  },
  {
    path: '/:quizkey/result',
    name: 'QuizResult',
    component: QuizResult,
    props: true,
  },

  {
    path: '/salon/home',
    name: 'SalonHome',
    component: SalonHome,
    props: true,
  },
  {
    path: '/salon/select',
    name: 'SalonSelect',
    component: SalonSelect,
    props: true,
  },
  {
    path: '/salon-waiting/:code',
    name: 'SalonWaiting',
    component: SalonWaiting,
    props: true,
  },
  {
    path: '/salon-finish/:code',
    name: 'SalonFinish',
    component: SalonFinish,
    props: true,
  },
  {
    path: '/salon-mng/:code',
    name: 'Salon Management',
    component: SalonMng,
    props: true,
  },
  {
    path: '/play/:code?',
    name: 'PlayCode',
    component: PlayCode,
    props: true,
  },
  {
    path: '/playroom/:code/:player',
    name: 'Play',
    component: Play,
    props: true,
  },
  {
    path: '/statistiques/:salon',
    name: 'Statistiques',
    component: Statistiques,
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
