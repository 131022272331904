<template>
  <div v-if="!loading">
    <header class="header">
      <v-container fluid>
        <v-row class="ml-10 mr-10">
          <v-col
            cols="12"
            sm="6"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-img
              alt="Quiz Addict - Reper"
              :src="'/assets/logos/' + $i18n.locale + '/general.png'"
              max-width="160"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="d-flex align-center justify-center justify-sm-end"
          >
            <v-btn
              color="#C1C1C1"
              elevation="0"
              class="mr-sm-10 pt-4 pb-4 pl-6 pr-6 white--text"
              to="/"
              >{{ $t('show-quizes') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </header>
    <v-main style="min-height: calc(100vh - 68px)">
      <v-img
        :src="
          '/assets/backgrounds/background-groupe/background-groupe-' +
          salon.category +
          '.jpg'
        "
        min-height="100vh"
      >
        <v-container fluid style="max-width: 1400px !important">
          <v-card class="ma-12 mx-6 pa-8">
            <v-row>
              <v-col>
                <h2 v-if="salon.step == 'finish'">
                  {{ $t('thanks') }}
                </h2>

                <h2 v-else>{{ $t('leaderboard') }}</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row class="leaderboard">
                  <v-col cols="12" lg="6">
                    <v-list
                      v-if="salon.step == 'results'"
                      class="players ml-12 mt-10 mr-12"
                    >
                      <v-list-item
                        v-for="(player, i) in players"
                        :key="player.id"
                        class="pos player-leaderboard mb-6"
                        :class="
                          salon.step == 'finish'
                            ? 'player-leaderboard-finish mb-12'
                            : 'player-leaderboard'
                        "
                      >
                        <v-list-item-action v-if="salon.step == 'results'">
                          <v-list-item-action-text
                            class="position"
                            :class="'pos_' + player.pos"
                          >
                            {{ player.pos }}{{ player.pos == 1 ? 'er' : 'ème' }}
                          </v-list-item-action-text>
                        </v-list-item-action>

                        <v-img
                          v-else
                          :src="'/assets/icons/image-' + (i + 1) + '.png'"
                          contain
                          class="finish-image"
                        >
                        </v-img>

                        <v-list-item-content
                          :class="
                            salon.step == 'finish' ? 'player-content' : ''
                          "
                        >
                          <v-list-item-title class="name">{{
                            player.name
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            v-if="salon.step == 'finish'"
                            class="score"
                          >
                            {{ player.score }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="salon.step != 'finish'">
                          <v-list-item-action-text class="score">
                            {{ player.score }}pts
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-list v-else class="players ml-12 mt-10 mr-12">
                      <template v-for="i in 3">
                        <v-list-item
                          v-if="players.filter((player) => player.pos == i)[0]"
                          :key="i"
                          three-line
                          class="pos player-leaderboard player-leaderboard-finish mb-12"
                        >
                          <v-img
                            :src="'/assets/icons/image-' + i + '.png'"
                            contain
                            class="finish-image"
                          >
                          </v-img>

                          <v-list-item-content class="player-content">
                            <v-list-item-title class="name">
                              {{
                                players
                                  .filter((player) => player.pos == i)
                                  .map((player) => {
                                    return player.name
                                  })
                                  .join(', ')
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              v-if="salon.step == 'finish'"
                              class="score"
                            >
                              {{
                                players.filter((player) => player.pos == i)[0]
                                  .score
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-list
                      v-if="players2.length > 0"
                      class="players ml-12 mt-lg-10 mr-12"
                    >
                      <v-list-item
                        v-for="player in players2"
                        :key="player.id"
                        class="pos player-leaderboard mb-6"
                      >
                        <v-list-item-action>
                          <v-list-item-action-text
                            class="position"
                            :class="'pos_' + player.pos"
                          >
                            {{ player.pos }}{{ player.pos == 1 ? 'er' : 'ème' }}
                          </v-list-item-action-text>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="name">{{
                            player.name
                          }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text class="score">
                            {{ player.score }}pts
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end pr-0">
                <v-btn
                  v-if="
                    salon.step != 'finish' && salon.index < questions.length
                  "
                  class="mt-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn"
                  color="primary"
                  @click="next"
                  dark
                  >{{ $t('next-question') }}
                  <v-icon class="ml-2">mdi-arrow-right-thick</v-icon></v-btn
                >
                <v-btn
                  v-else-if="salon.step == 'finish'"
                  class="mt-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn"
                  color="primary"
                  @click="next"
                  dark
                  >{{ $t('finish') }}
                  <v-icon class="ml-2">mdi-arrow-right-thick</v-icon></v-btn
                >
                <v-btn
                  v-else
                  class="mt-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn"
                  color="primary"
                  @click="next"
                  dark
                  >{{ $t('leaderboard-btn') }}
                  <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-img>
    </v-main>
  </div>
</template>

<script>
import { db } from '../firebase'

export default {
  name: 'SalonFinish',
  props: ['code'],
  data: () => ({
    salon: null,
    playersDb: [],
    questions: [],
    players: [],
    players2: [],
    loading: true,
  }),
  async created() {
    await this.$bind('salon', db.collection('salons').doc(this.code))
    await this.$bind(
      'playersDb',
      db
        .collection('/salons/' + this.code + '/players')
        .orderBy('score', 'desc')
    )
    await this.$bind(
      'questions',
      db.collection('/salons/' + this.code + '/questions')
    )
    this.sortPlayers()
  },
  methods: {
    sortPlayers() {
      let pos = 0
      let oldScore = -1
      for (let i = 0; i < this.playersDb.length; i++) {
        if (i == 10 && this.salon.step == 'results') break
        if (oldScore != this.playersDb[i].score) pos++

        this.players.push({
          name: this.playersDb[i].name,
          score: this.playersDb[i].score,
          pos: pos,
        })
        oldScore = this.playersDb[i].score
      }

      if (this.salon.step == 'finish') {
        this.players.forEach((player) => {
          if (player.pos > 3) {
            this.players2.push(player)
          }
        })

        this.players = this.players.filter(
          (player) => !this.players2.includes(player)
        )
      } else {
        if (this.players.length > 5) {
          const players2start =
            this.players.length >= 3
              ? 3
              : this.players.length;

          this.players2 = this.players.slice(players2start)
          this.players = this.players.slice(0, players2start)
        }
      }

      this.loading = false
    },
    next() {
      if (this.salon.step === 'finish') {
        this.$router.push('/')
      } else {
        this.$router.push('/salon/' + this.code)
        this.$router.push('/salon-mng/' + this.code)
        db.collection('salons').doc(this.code).update({
          step: 'starting',
        })
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$i18n.locale === 'de') {
          document.title = 'Bischfit | by REPER'
        } else {
          document.title = 'Quizaddict | by REPER'
        }
      },
    },
  },
}
</script>

<style lang="scss">
:root {
  --primary-color: #b20028 !important;
  --secondary-color: #b20028 !important;
}
.player {
  background: #b20028;
  border-radius: 10px;
  margin: 10px;
  display: inline-block;
  padding: 10px;
  color: #fff;
}
.backgroundImg {
  /* background: #fbb700;*/
  background-position: center;
  background-size: cover;
  width: 100vw;
}

.backgroundImg > .v-content__wrap > .container {
  height: auto;
}

@media screen and (min-width: 609px) and (max-width: 960px) {
  .backgroundImg > .v-content__wrap > .container {
    height: auto;
  }
}

@media screen and (max-width: 608px) {
  .backgroundImg > .v-content__wrap > .container {
    height: auto;
  }
}

@media screen and (max-width: 960px) and (max-height: 1000px) {
  .backgroundImg > .v-content__wrap > .container {
    height: auto;
  }
}

@media screen and (min-width: 961px) and (max-height: 715px) {
  .backgroundImg > .v-content__wrap > .container {
    height: auto;
  }
}

h3 {
  margin-top: 50px;
  font-weight: normal;
}

.next-btn {
  font-family: 'Fredoka One', cursive;
  font-size: 20px !important;
}

.leaderboard {
  box-sizing: border-box;
  border: 6px solid #e8e8e8;
  border-radius: 8px;
  background-color: #f0f0f0;

  .players {
    background-color: transparent !important;

    .player-leaderboard {
      min-height: 120px;
      border-radius: 6px;
      background-color: #333333;

      .position {
        color: #ffffff !important;
        font-family: 'Fredoka One' !important;
        font-size: 30px !important;
        letter-spacing: 0 !important;
        line-height: 36px !important;

        &.pos_1 {
          color: #cd990c !important;
        }

        &.pos_2 {
          color: #939393 !important;
        }

        &.pos_3 {
          color: #b77a2e !important;
        }
      }

      .finish-image {
        max-width: 150px;
        max-height: 150px;
      }

      .player-content {
        text-align: right;

        .v-list-item__title {
          white-space: unset !important;
        }

        .name {
          font-size: 36px !important;
          font-weight: bold !important;
          line-height: 42px !important;
        }

        .score {
          font-size: 36px !important;
        }
      }

      .name {
        color: #ffffff;
        font-family: 'Roboto', sans-serif !important;
        font-size: 20px !important;
        font-weight: 900 !important;
        letter-spacing: 0 !important;
        line-height: 37px !important;
      }

      .score {
        color: #ffffff !important;
        font-family: 'Roboto', sans-serif !important;
        font-size: 20px !important;
        letter-spacing: 0 !important;
        line-height: 37px !important;
      }
    }
  }
}
</style>
