<template>
  <v-app>
    <router-view :key="$route.fullPath" class="content" />
  </v-app>
</template>

<script>
import apiCall from '@/utils/api.js'

export default {
  name: 'App',
  title: '',
  data: () => ({
    homelink: '',
    appKey: 1,
  }),
  created() {
    if (localStorage.quizkey) {
      apiCall({ url: '/' + localStorage.quizkey, method: 'get' }).then(
        (resp) => {
          localStorage.quiz = JSON.stringify(resp.data)
        }
      )
    } else {
      this.isquiz = false
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

h1 {
  color: #b11c33 !important;
  font-family: 'Fredoka One', cursive !important;
  font-size: 45px !important;
  line-height: 45px !important;

  @media screen and (min-width: 961px) {
    font-size: 50px !important;
    line-height: 60px !important;
  }

  @media screen and (min-width: 1264px) {
    font-size: 56px !important;
    line-height: 75px !important;
  }
}

h2 {
  color: #333333 !important;
  font-family: 'Fredoka One', cursive !important;
  font-size: 35px !important;
  line-height: 35px !important;

  @media screen and (min-width: 961px) {
    font-size: 40px !important;
    line-height: 37px !important;
  }

  @media screen and (min-width: 1264px) {
    font-size: 45px !important;
    line-height: 37px !important;
  }
}

h3 {
  color: #333333 !important;
  font-family: 'Fredoka One', cursive !important;
  font-size: 20px !important;
  line-height: 27px !important;

  @media screen and (min-width: 961px) {
    font-size: 25px !important;
    line-height: 30px !important;
  }

  @media screen and (min-width: 1264px) {
    font-size: 30px !important;
    line-height: 37px !important;
  }
}

.intro {
  color: black !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 20px !important;
  line-height: 30px !important;

  @media screen and (min-width: 961px) {
    font-size: 25px !important;
    line-height: 40px !important;
  }

  @media screen and (min-width: 1264px) {
    font-size: 30px !important;
    line-height: 52px !important;
  }
}

p {
  color: #333333 !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  line-height: 18px !important;

  @media screen and (min-width: 961px) {
    font-size: 17px !important;
    line-height: 20px !important;
  }

  @media screen and (min-width: 1264px) {
    font-size: 18px !important;
    line-height: 24px !important;
  }
}
</style>
