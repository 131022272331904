<template>
    <div>
        <header class="header">
            <v-container>
                <v-row>
                    <v-col cols="6" class="d-flex align-center justify-start">
                        <v-img alt="Quiz Addict - Reper" :src="'/assets/logos/' + $i18n.locale + '/general.png'" max-width="160" />
                    </v-col>
                    <v-col cols="6" class="d-flex align-center justify-end">
                        <router-link to="/">
                            <v-img alt="Logo Reper - Promotion de la santé" src="/assets/logos/reper.svg" width="150px" height="80px" contain />
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </header>
        <span class="bg"></span>
        <v-main class="backgroundImg" style="min-height: 100%">
            <v-img :src="'/assets/backgrounds/background-groupe/background-groupe-' + salon.category + '.jpg'" min-height="100%">
                <v-container class="d-flex align-center justify-center" style="height: 100%">
                    <v-card
                        class="ma-12 mx-6 d-flex pa-12 justify-center align-center flex-wrap"
                        style="width: 95%; min-height: calc(100vh - 300px); position: relative"
                    >
                        <div v-if="salon.step === 'question' && step !== 'waitingresults'">
                            <v-radio-group v-if="salon.workingquestion.type == 'singleChoice'" v-model="answer">
                                <v-radio
                                    v-for="answer in salon.workingquestion.answers"
                                    :key="answer.id"
                                    :label="answer.answer"
                                    :value="answer.id"
                                    class="ma-3"
                                ></v-radio>
                            </v-radio-group>

                            <div v-else-if="salon.workingquestion.type == 'multipleChoice'">
                                <v-checkbox
                                    v-for="an in salon.workingquestion.answers"
                                    :key="an.id"
                                    :label="an.answer"
                                    :value="an.id"
                                    v-model="answers"
                                    class="ma-0 pa-0"
                                    style="margin: 10px 0"
                                ></v-checkbox>
                            </div>
                            <v-btn
                                class="ma-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn answer-btn"
                                color="primary"
                                @click="check"
                                style="bottom: -10px; right: 0"
                                dark
                            >
                                {{ $t("answer") }}
                            </v-btn>
                        </div>
                        <div v-else-if="step != 'waiting' && salon.step != 'finish'">
                            <v-img v-if="correct" src="/pictogrammes_juste.svg" max-height="120px" contain></v-img>

                            <v-img v-if="!correct" src="/pictogrammes_faux.svg" max-height="120px" contain></v-img>
                            <p class="text-center">
                                {{ $t("waiting-responses") }}
                            </p>
                        </div>
                        <div
                            class="d-flex align-center justify-center flex-wrap blink_text"
                            v-if="step === 'waiting' && salon.step !== 'results' && salon.step !== 'finish' && salon.step !== 'question'"
                        >
                            <h1 style="color: #ddd; font-size: 50px; width: 100%; text-align: center; margin-bottom: 20px">{{ $t("waiting") }}</h1>
                            <v-icon color="grey" style="font-size: 60px">mdi-timer-sand</v-icon>
                        </div>
                        <div class="d-flex align-center justify-center flex-wrap" v-if="salon.step === 'finish'">
                            <h1 style="color: #ddd; font-size: 50px; width: 100%; text-align: center; margin-bottom: 20px">
                                {{ $t("thanks-for") }}
                                <br />
                                {{ $t("participation") }}
                            </h1>
                            <v-icon color="grey" style="font-size: 60px">mdi-emoticon-excited</v-icon>
                        </div>
                    </v-card>
                </v-container>
            </v-img>
        </v-main>
    </div>
</template>

<script>
import { db } from "../firebase";
export default {
    name: "Play",
    props: ["code", "player"],
    data: () => ({
        answer: null,
        answers: [],
        salon: {},
        question: null,
        step: "waiting",
        correct: false,
    }),
    async mounted() {
        await this.$bind("salon", db.collection("salons").doc(this.code));

        if (!this.salon || !this.salon.workingquestion || Object.keys(this.salon.workingquestion).length === 0) return;

        await this.$bind("question", db.collection("/salons/" + this.code + "/questions").doc(this.salon.workingquestion.id));

        if (this.question.responses == undefined) return;

        this.question.responses.forEach((response) => {
            if (response.player == this.player) this.step = "waitingresults";
        });
    },
    watch: {
        salon() {
            if (this.salon.step == "question") this.step = "";

            if (this.salon.step != "results") return;

            const workQuestionDocumentReference = db.collection("/salons/" + this.code + "/questions").doc(this.salon.workingquestion.id);

            db.runTransaction(async (transaction) => {
                return transaction.get(workQuestionDocumentReference).then((workQuestionDocument) => {
                    if (!workQuestionDocument.exists) return;

                    const workQuestion = workQuestionDocument.data();

                    const responses = workQuestion.responses != undefined ? workQuestion.responses : [];

                    if (responses.filter((response) => response.player == this.player).length > 0) return;

                    responses.push({
                        player: this.player,
                        answer: false,
                    });

                    transaction.update(workQuestionDocumentReference, {
                        responses: responses,
                    });
                });
            });
        },
    },
    methods: {
        async check() {
            this.step = "waitingresults";

            let now = new Date().getTime();
            let correct = false;

            if (this.answer) {
                for (const answer of this.salon.workingquestion.answers) {
                    if (parseInt(answer.id) == parseInt(this.answer)) {
                        correct = answer.correct == "1";
                    }
                }

                this.answer = null;
            } else {
                var nbCorrect = 0;
                var userCorrect = 0;
                for (const answer of this.salon.workingquestion.answers) {
                    if (answer.correct === "1") {
                        nbCorrect++;
                    }
                }

                for (const answer of this.salon.workingquestion.answers) {
                    if (answer.correct == "1") {
                        if (this.answers.includes(answer.id)) {
                            userCorrect++;
                        } else {
                            userCorrect = 0;
                            break;
                        }
                    } else {
                        if (this.answers.includes(answer.id)) {
                            userCorrect = 0;
                            break;
                        }
                    }
                }
                this.answers = [];

                correct = userCorrect == nbCorrect;
            }

            this.correct = correct;
            this.answer = null;
            this.answers = [];

            if (this.salon.type != "challenge") {
                const playerDocument = await db
                    .collection("/salons/" + this.code + "/players")
                    .doc(this.player)
                    .get();

                if (!playerDocument.exists) return;

                const newScore = (playerDocument.data().score += correct);

                await db
                    .collection("/salons/" + this.code + "/players")
                    .doc(this.player)
                    .update({
                        score: newScore,
                    });

                const workQuestionDocumentReference = db.collection("/salons/" + this.code + "/questions").doc(this.salon.workingquestion.id);

                db.runTransaction(async (transaction) => {
                    return transaction.get(workQuestionDocumentReference).then((workQuestionDocument) => {
                        if (!workQuestionDocument.exists) return;

                        const workQuestion = workQuestionDocument.data();

                        const responses = workQuestion.responses != undefined ? workQuestion.responses : [];

                        if (responses.filter((response) => response.player == this.player).length > 0) return;

                        responses.push({
                            player: this.player,
                            answer: correct,
                        });

                        transaction.update(workQuestionDocumentReference, {
                            responses: responses,
                        });
                    });
                });
            } else {
                if (!correct) {
                    const workQuestionDocumentReference = db.collection("/salons/" + this.code + "/questions").doc(this.salon.workingquestion.id);

                    db.runTransaction(async (transaction) => {
                        return transaction.get(workQuestionDocumentReference).then((workQuestionDocument) => {
                            if (!workQuestionDocument.exists) return;

                            const workQuestion = workQuestionDocument.data();

                            const responses = workQuestion.responses != undefined ? workQuestion.responses : [];

                            if (responses.filter((response) => response.player == this.player).length > 0) return;

                            responses.push({
                                player: this.player,
                                answer: correct,
                            });

                            transaction.update(workQuestionDocumentReference, {
                                responses: responses,
                            });
                        });
                    });
                } else {
                    const responseTime = (now - this.salon.startTime) / 1000;

                    if (responseTime > this.salon.time) return;

                    const timePercentage = (this.salon.time - responseTime) / this.salon.time;

                    let points = Math.round(timePercentage * 100);
                    if (points < 40) {
                        points = 40;
                    } else if (responseTime <= 3) {
                        points = 100;
                    }

                    const playerDocument = await db
                        .collection("/salons/" + this.code + "/players")
                        .doc(this.player)
                        .get();

                    if (!playerDocument.exists) return;

                    let newScore = (playerDocument.data().score += points);

                    await db
                        .collection("/salons/" + this.code + "/players")
                        .doc(this.player)
                        .update({
                            score: newScore,
                        });

                    const workQuestionDocumentReference = db.collection("/salons/" + this.code + "/questions").doc(this.salon.workingquestion.id);

                    db.runTransaction(async (transaction) => {
                        return transaction.get(workQuestionDocumentReference).then((workQuestionDocument) => {
                            if (!workQuestionDocument.exists) return;

                            const workQuestion = workQuestionDocument.data();

                            const responses = workQuestion.responses != undefined ? workQuestion.responses : [];

                            if (responses.filter((response) => response.player == this.player).length > 0) return;

                            responses.push({
                                player: this.player,
                                answer: correct,
                            });

                            transaction.update(workQuestionDocumentReference, {
                                responses: responses,
                            });
                        });
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss">
:root {
    --primary-color: #b20028 !important;
    --secondary-color: #b20028 !important;
}
.body {
    font-size: 22px;
}
.blink_text {
    animation-name: blinker;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.next-btn {
    font-family: "Fredoka One", cursive;
    font-size: 20px !important;
}

.answer-btn {
    @media screen and (min-width: 600px) {
        position: absolute;
    }
}

.v-btn:not(.v-btn--round).v-size--default {
    height: auto;
    color: white;
}

@keyframes blinker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}
</style>
